.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.App-action {
  cursor: pointer;
}

.App-action:hover {
  background-color: #ccc;
}

.App-form input {
  margin: 15px;
}

.App-form-error {
  color: red;
}

.App-buttons {
  display: flex;
  justify-content: flex-end;
}

.App-buttons button {
  margin: 15px;
}

.App-table table {
  border-spacing: 2px;
}

.App-table td {
  padding: 6px;
}
